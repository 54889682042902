:root {
  --primary: #2193d1;
  --n700: #6e7075;
  --n400: #dcdfe6;
  --n250: #eaecef;
  --n200: #f0f3f7;
  --n000: #ffffff;
  --b600: #1474a8;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #022133;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// checkbox cusom design
div[class^='ckbx'] input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

.ckbx {
  padding-left: 26px;
  margin-right: 100px;
}
.ckbx label {
  position: relative;
  cursor: pointer;
  display: inline-block;
}
.ckbx label:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.4);

  box-shadow: 0 0 1px 1px inset #cecccc;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  left: -26px;
}

.ckbx input[type='checkbox']:checked + label:before {
  background: var(--primary);
  content: '';
  color: #fff;
  border: 1px solid var(--primary);
  box-shadow: none;
}

.ckbx input[type='checkbox']:disabled + label {
  opacity: 0.5;
}

.ckbx input[type='checkbox']:disabled + label:before {
  background: var(--n700);
  border-color: var(--n700);
}

.ckbx input[type='checkbox']:checked + label:after {
  content: 'L';
  color: #fff;
  transform: scaleX(-1) rotate(-41deg);
  z-index: 22;
  font-size: 18px;
  line-height: 1;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  left: -25px;
  top: 0;
}
