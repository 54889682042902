header {
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding-top: 8px;
  grid-column-gap: 16px;

  .header__navigation {
    display: flex;
  }
  a {
    position: relative;
    margin-right: 20px;
    text-decoration: none;
    span {
      padding: 14px 16px;
      position: relative;
      display: flex;
      justify-content: space-between;
      color: var(--n700);
      font-weight: 500;
      border-radius: 4px 4px 0 0;
      text-transform: uppercase;
      font-size: 12px;
    }
    &:hover {
      span {
        background: var(--n250);
      }
    }
    &.active {
      &:before,
      &:after {
        content: '';
        background: var(--n200);
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: 0;
        z-index: 1;
      }
      &:before {
        left: -10px;
      }
      &:after {
        right: -10px;
      }

      span {
        background: var(--n200);
        color: var(--b600);
        font-weight: 500;

        &:after,
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          border-radius: 6px;
          background: var(--n400);
          z-index: 2;
          position: absolute;
          bottom: 0px;
        }
        &:before {
          left: -20px;
        }
        &:after {
          right: -20px;
        }
      }
    }
  }
}
