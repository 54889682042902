.Upload {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  max-width: 972px;

  .button {
    background: #2193d1;
    display: inline-block;
    color: #fff;
    padding: 8px 16px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-top: 16px;

    svg {
      margin-right: 5px;
      fill: #fff;
      height: 17px;
    }

    input {
      display: none;
    }

    &:disabled,
    &.disabled {
      background: #dcdfe6;
      color: #a1a8b3;
      svg {
        margin-right: 5px;
        fill: #a1a8b3;
        height: 17px;
      }
    }
  }
}

.file {
  margin: 12px 0;

  .label {
    color: var(--n700);
    font-size: 14px;
    font-weight: 500;
  }

  .close {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 12px;
    height: 12px;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 5px;
    content: ' ';
    height: 12px;
    width: 2px;
    background-color: var(--n700);
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}

.fileName {
  background: #f0f2f5;
  border-radius: 3px;
  margin-top: 8px;
  padding: 6px 8px;
  width: 50%;
  position: relative;
}
.card-content {
  padding: 24px 32px;
}
.card-footer {
  background: #f0f2f5;
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
}

pre {
  white-space: normal;
  background: var(--n700);
  color: #fff;
  padding: 10px;
  border-radius: 3px;
  position: relative;
}

.lg {
  font-size: 120%;
  padding: 20px;
  max-width: 100%;
}

.show-example {
  color: var(--primary);
  font-weight: 500;
  cursor: pointer;
}
