body {
  background: #f0f3f7;
  font-size: 14px;
  margin-left: 48px;
  line-height: 1.4;
}

body.users-container {
  display: block;
}
p {
  color: #6e7075;
  margin-bottom: 6px;
  margin-top: 0;

  &.dark {
    color: #022133;
  }
}

.button-icon {
  height: 17px;
}
h3 {
  font-weight: 500;
  font-size: 22px;
}
h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 8px;
  margin-top: 0;
}

.frida-input {
  position: relative;
  padding: 0;
  margin: 5px;
  border: none;
  overflow: visible;
  margin-bottom: 36px;
  font-size: 14px;
}
.frida-input.select {
  margin-bottom: 32px;
}
.frida-input select {
  display: block;
  width: 100%;
  height: 40px;
  color: #555;
  background-color: #e5e8ee;
  background-image: none;
  border: none;
  border-radius: 3px;
}
.frida-input.password {
  margin-bottom: 68px;
}
.frida-input input {
  box-sizing: border-box;
  width: 100%;
  padding: 12px 10px 8px 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #ddd;
  font-size: 100%;
  outline: none;
  cursor: text;
}
.frida-checkbox {
  position: relative;
  padding: 0;
  margin: 5px;
  border: none;
  overflow: visible;
  margin-bottom: 36px;
  font-size: 14px;
}

.frida-input > input:-webkit-autofill ~ label {
  transform: scale(0.6);
  font-size: 130%;
}
.frida-input input::-webkit-input-placeholder {
  transition: color 300ms ease;
}
.frida-input input:not(:focus)::-webkit-input-placeholder {
  color: transparent;
}
.frida-input hr {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2px;
  border: none;
  background: #2193d1;
  font-size: 1px;
  will-change: transform, visibility;
  transition: all 200ms ease-out;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  visibility: hidden;
  z-index: 10;
}
.frida-input input:focus ~ hr {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  visibility: visible;
}
.frida-input label {
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 100%;
  color: #6e7075;
  -webkit-transform-origin: 0 -150%;
  transform-origin: 0 -150%;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
  pointer-events: none;
  /* transform: scale(0.6); */
}
.frida-input input:focus ~ label,
.frida-input input:valid ~ label {
  font-size: 120%;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
}

.frida-logo {
  height: 94px;
  margin: 0 auto 48px auto;
  display: block;
}

button,
.button {
  font-family: 'Roboto', sans-serif;
  background: #2193d1;
  color: #fff;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s;
  border-radius: 3px;
  /* width: 100%; */
  min-width: 80px;
  padding: 10px 5px;
  text-transform: uppercase;
  font-weight: 500;
}

.userList .col.col-lg {
  flex: 2;
}
.col-buttons {
  display: flex;
  text-align: center;
  justify-content: flex-end;
}

.col-buttons button,
.col-buttons .button {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 1px;
  /* border-radius: 0; */
  margin-right: 5px;
}
button:disabled,
.button:disabled {
  background: #dcdfe6;
  color: #a1a8b3;
}

.button {
  text-decoration: none;
  box-sizing: border-box;
}

.button.lg {
  padding: 10px 20px;
}
.button.full {
  width: 100%;
}
.delete {
  background: #e80000;
}
.button.edit {
  background: #f99613;
}

.button.deactivate {
  background: #e8e8e8;
  color: #a2a2a2;
}

.alert,
.success {
  background: #faeceb;
  border-radius: 3px;
  margin: 20px 0;
  padding: 6px 8px;
  max-width: 50%;
}
.success {
  background: #ebf5eb;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    fill: #239b36;
  }
}

.login .alert {
  color: #c22a1e;
  font-weight: 500;
  max-width: 100%;
  display: flex;
  align-items: center;

  .alert__icon {
    margin-right: 18px;
    height: 16px;
    width: 16px;
    svg {
      fill: #c22a1e;
    }
  }
}

.alert__heading {
  color: #c22a1e;
  font-weight: 500;
  display: flex;
  align-items: center;
  .alert__icon {
    margin-right: 18px;
    height: 16px;
    width: 16px;
    svg {
      fill: #c22a1e;
    }
  }
}

.success.alert {
  .alert__heading {
    color: #12651f;
    .alert__icon {
      margin-right: 18px;
      height: 20px;
      width: 20px;
      svg {
        fill: #239b36;
      }
    }
  }
}
.alert__content {
  margin-left: 32px;
}

header {
  background: #dcdfe6;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  padding: 0 32px;
}

.logo-icon {
  margin-bottom: 12px;
  width: 48px;
}
.title {
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  padding-left: 48px;
}
.sidebar {
  text-align: center;
}

main {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-column-gap: 16px;
  padding: 0 32px;
  height: auto;
}

.icon {
  fill: #e1e4eb;
  height: 250px;
  width: 250px;
  margin: 0 auto;

  &.icon-sm {
    height: 64px;
    width: 64px;
  }
}
.content {
  // padding: 0 48px;

  &__component {
    display: grid;
    grid-template-columns: 212px auto;
  }
}

.userList,
.addUserList {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  background: #fff;
  padding: 24px;
  position: relative;
}

.userList .row {
  display: flex;
  padding: 5px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.userList .userList__header {
  display: flex;
  padding: 5px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  text-transform: capitalize;
  border-top: 1px solid #ddd;
}
.userList .userList__header .col {
  font-size: 14px;
  color: #929292;
}
.userList .col {
  flex: 1;
  padding: 10px 5px;
  font-size: 14px;
}

.userList .col:last-child {
  display: flex;
  justify-content: flex-end;
}
.userControls {
  text-align: right;
  margin-bottom: 20px;
}
.userControls button {
  width: auto;
  min-width: 200px;
}

.addUserList {
  max-width: 400px;
}

nav {
  background: #022133;
  overflow: hidden;
  width: 48px;
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2),
    0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9;
  padding: 16px 0;
  box-sizing: border-box;
  text-align: center;
  transition: 0.2s cubic-bezier(0.7, 0, 0.3, 1) 0.3s;
}
nav .navigation {
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
nav .navigation a {
  text-align: left;
  padding: 16px;
  opacity: 0.6;
  transition: all 0.2s;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:last-child {
    margin-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}
nav .navigation a:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
nav .navigation a span.label {
  margin-left: 13px;
}
nav .navigation a.active,
nav .navigation a:hover {
  background: #03567f;
  opacity: 1;
}

nav .navigation .icon {
  fill: #fff;
  width: 20px;
  height: 20px;
}

.success {
}

@keyframes hide {
  0% {
    height: auto;
  }
  80% {
    height: 0;
  }
  100% {
    height: 0;
    display: none;
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
}
.loader {
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  overflow: hidden;
  border-top: 0.7em solid rgba(0, 0, 0, 0.1);
  border-right: 0.7em solid rgba(0, 0, 0, 0.1);
  border-bottom: 0.7em solid rgba(0, 0, 0, 0.1);
  border-left: 0.7em solid var(--n700);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .content__component {
    grid-template-rows: auto auto;
    grid-row-gap: 32px;
    grid-template-columns: auto;
  }
}

@media (max-width: 1024px) {
  main {
    grid-template-columns: 1fr;
  }
  .sidebar {
    display: none;
  }
}
