.Download {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  max-width: 972px;

  .button {
    background: var(--primary);
    display: inline-block;
    color: #fff;
    padding: 8px 16px;
    border-radius: 3px;
    text-transform: uppercase;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    margin-top: 16px;

    svg {
      margin-right: 5px;
      fill: #fff;
      height: 17px;
    }

    input {
      display: none;
    }

    &:disabled,
    &.disabled {
      background: #dcdfe6;
      color: #a1a8b3;
      svg {
        margin-right: 5px;
        fill: #a1a8b3;
        height: 17px;
      }
    }
  }
}
.card-content {
  padding: 24px 32px;
}
.card-footer {
  background: #f0f2f5;
  min-height: 84px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
}

.stepper {
  .step {
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: 24px auto;
    grid-column-gap: 32px;
    position: relative;
    min-height: 80px;

    &:before {
      content: '';
      background: #dcdfe6;
      width: 2px;
      position: absolute;
      left: 11px;
      top: 32px;
      bottom: -11px;
    }
    &:last-child {
      &:before {
        content: none;
      }
    }

    &__label {
      border-radius: 100%;
      width: 24px;
      height: 24px;
      background: var(--primary);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__title {
      font-size: 18px;
      line-height: 1;
      margin-bottom: 6px;
      font-weight: 500;
    }
    &__description {
      // margin-bottom: 32px;
    }

    &__component.checkboxes {
      display: flex;
      padding: 36px 0;
    }

    &__component.dates {
      margin-top: 12px;

      .frida-input {
        margin-bottom: 8px;
      }
    }
  }
}

.react-datepicker {
  border: none;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  font-family: inherit;
  &-wrapper {
    margin-right: 16px;
  }
  &__navigation {
    min-width: auto;
  }

  &__day {
    border: 4px solid #fff;
    border-radius: 100%;
    &:hover {
      border-radius: 100%;
      border-color: #f0f0f0;
    }
  }
  &__header {
    background: #fff;
    border-color: #fff;

    &__dropdown--scroll {
      padding: 5px;
    }
  }
  &__day-name {
    color: var(--n700);
    font-weight: 500;
    border: 4px solid transparent;
  }
  &__day--keyboard-selected,
  &__day--selected {
    background: var(--primary);
    border-color: #9ed2ec;
  }

  &__triangle {
    display: none;
  }

  &-popper[data-placement^='bottom'] {
    margin-top: 0;
  }
  &-popper[data-placement^='bottom'] {
    &__triangle,
    &__triangle::before {
      border-bottom-color: #fff;
      display: none;
    }
  }

  &_day--outside-month {
    opacity: 0.7;
  }

  &__navigation--years-upcoming,
  &__navigation--years-previous {
    min-width: 0 !important;
    width: 0 !important;
  }

  &__year-dropdown,
  &__month-dropdown,
  &__month-year-dropdown {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
      0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
    border: none;
  }

  &__year-option,
  &__month-option,
  &__month-year-option {
    background: #ffffff;
    padding: 5px 0;

    &:hover {
      background: var(--n250);
    }
  }
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  top: 6px;
}
