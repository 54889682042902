
.login{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .login-form {
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
      0 1px 14px 0 rgba(0, 0, 0, 0.12);
    color: #6e7075;
    background: #fff;
    border-radius: 3px;
    padding: 48px;
    width: 452px;
    box-sizing: border-box;
  }